import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product } from 'src/@omnial/_models/catalog/product.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { StarShipItSettings } from 'src/@omnial/_models/order/star-shipping.model';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { TrackingService } from 'src/@omnial/_services/external/tracking.service';
import { CanonicalService } from 'src/@omnial/_services/navigation/canonical.service';
import { MenuService } from 'src/@omnial/_services/navigation/menu.service';
import { SiteMapService } from 'src/@omnial/_services/navigation/sitemap.service';
import { StarShipItService } from 'src/@omnial/_services/order/star-shipping.service';
import { CurrencyService } from 'src/@omnial/_services/price/currency.service';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header-mobile',
  templateUrl: './../../../app/shared/header-mobile/header-mobile.component.html',
  styleUrls: ['./../../../app/shared/header-mobile/header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnDestroy {
  @Input() inputSideNav: MatSidenav;
  @Input() menuPosition: string;
  @Input() menuOverflow: string = 'visible';
  @Input() menuTop: string;
  @Input() menuBackground = environment.topMenuBackground;
  @Input() menuLogo = true;

  public customer: Customer;
  public isCheckout = false;
  public seName;
  public pushRight = -333;
  public closeTimeout: NodeJS.Timeout;
  public closeResetTimeout: NodeJS.Timeout;
  public resetTimeout: NodeJS.Timeout;
  public loggedIn = false;
  public shippingSettings: StarShipItSettings;
  public subscriptions: Subscription[] = [];

  constructor(
    public settings: AppSettings,
    public router: Router,
    public customerService: CustomerService,
    public sitemapService: SiteMapService,
    public trackingService: TrackingService,
    public canonicalService: CanonicalService,
    public menuService: MenuService,
    public starShipItService: StarShipItService,
    public currencyService: CurrencyService) {
    this.subscriptions.push(this.customerService.customer.subscribe((res) => {
      this.customer = res as Customer;
      if (localStorage.getItem('token') && this.customer) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    }));
    this.subscriptions.push(router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          if (localStorage.getItem('token') && this.customer) {
            this.loggedIn = true;
          } else {
            this.loggedIn = false;
          }
          if (event.url.startsWith('/checkout')) {
            this.isCheckout = true;
          } else {
            this.isCheckout = false;
          }
          const urlParts = event?.urlAfterRedirects?.split('/');
          if (urlParts) {
            this.seName = urlParts[urlParts.length - 1];
          }
        }
      }));
    this.subscriptions.push(this.starShipItService.starShipItSettings.subscribe(res => {
      if (res) {
        this.shippingSettings = res as StarShipItSettings;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public closeSubMenus(): void {
    if (window.innerWidth < 960) {
      if (this.settings.megaMobileNav) {
        this.menuService.closeAllSubMenus();
      } else {
        this.sitemapService.closeAllSubMenus();
      }
    }
  }

  public pushPull(): void {
    clearTimeout(this.closeTimeout);
    clearTimeout(this.closeResetTimeout);
    clearTimeout(this.resetTimeout);
    if (this.pushRight < -300) { // Open
      this.pushRight = -10;
      this.menuOverflow = 'unset';
    } else { // Close
      this.pushRight = -333;
      this.menuOverflow = 'unset';
      this.resetTimeout = setTimeout(() => {
        this.menuOverflow = 'visible';
      }, 1000);
    }
  }

  productClick(product: Product): void {
    this.trackingService.pushProductClick(product, this.seName, "Mini Cart", 1);
    if (!product.canonical) {
      this.subscriptions.push(this.canonicalService.productCanonical(product).subscribe(
        res => {
          if (res) {
            this.router.navigate([`${res}/${product.seName}`]);
          } else {
            this.router.navigate([`/products/${product.id}`]);
          }
        }
      ))
    } else {
      this.router.navigate([product.canonical]);
    }
  }

  spendMore(): string {
    return this.settings.spendMore(this.shippingSettings, this.customer);
  }
}
