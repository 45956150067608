<ng-container *ngIf="menuItems">
  <div *ngFor="let menu of menuItems" class="menu-item">
    <a *ngIf="menu.link && !menu.children" mat-button class="d-flex flex-row justify-content-start align-items-center sidenav-link sidenav-{{level}}" [routerLink]="[menu.link]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:false}" (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
      <span class="menu-title text-truncate">{{menu.name}}
        <picture *ngIf="menu.isLogo">
          <source [srcset]="menu.imageUrl + '.webp'" type="image/webp" />
          <img *ngIf="menu.isLogo" [src]="menu.imageUrl" [alt]="menu.name">
        </picture>
      </span>
    </a>
    <button *ngIf="menu.children" mat-button class="d-flex flex-row justify-content-start align-items-center sidenav-link sidenav-{{level}}" (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
      <span class="menu-title text-truncate">{{menu.name}}</span>
      <mat-icon class="menu-expand-icon transition-2">chevron_right</mat-icon>
    </button>
    <div *ngIf="menu.children" class="sub-menu" [id]="'sub-menu-'+menu.id">
      <app-sidenav-mega [menuItems]="menu.children" [menuParentId]="menu.id" [level]="level+1"></app-sidenav-mega>
    </div>
  </div>
</ng-container>
