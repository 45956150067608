<footer class="footer hide-print">
  <div class="flex-100" style="margin: 0 auto">
    <div class="d-flex flex-row flex-wrap justify-content-start align-items-start hide-print">
      <div class="flex-10 d-none d-md-inline hide-print">&nbsp;</div>
      <div class="flex-90 flex-md-30 hide-print">
        <ul class="list-unstyled">
          <li *ngFor="let footerLink of footerOneLinks"><a [routerLink]="[footerLink.slug]" routerLinkActive="router-link-active">{{footerLink.title}}</a></li>
        </ul>
      </div>
      <div class="flex-90 flex-md-30 hide-print">
        <ul class="list-unstyled">
          <li *ngFor="let footerLink of footerTwoLinks"><a [routerLink]="[footerLink.slug]" routerLinkActive="router-link-active">{{footerLink.title}}</a></li>
        </ul>
      </div>
      <div class="flex-90 flex-md-30 hide-print">
        <ul class="list-unstyled">
          <li *ngFor="let footerLink of footerThreeLinks"><a [routerLink]="[footerLink.slug]" routerLinkActive="router-link-active">{{footerLink.title}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>

<footer class="footer border-top hide-print copywrite">
  <div class="flex-fill theme-container text-center hide-print">
    This site is protected by reCAPTCHA and the Google <a rel="noreferrer" target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a rel="noreferrer" target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
  </div>
  <div class="flex-fill theme-container text-center hide-print">
    {{settings?.companyName}} © All rights reserved {{year}} Powered By
    <!-- <a rel="noopener" href="https://omnial.commerce.com" target="_blank">Omnial Commerce</a> -->
    <a href="#" (click)="omnialCommerce();" style="color:#fff !important">Omnial Commerce</a>
    <a rel="noreferrer" target="_blank" [href]="cacheBust" style="color:#000 !important">__</a>
  </div>
</footer>
