export class UrlRequest {
  slug: string;
  parentSlug: string;
  path: string;

  constructor(slug: string, parentSlug: string, path: string) {
    this.slug = slug;
    this.parentSlug = parentSlug;
    this.path = path;
  }
}
