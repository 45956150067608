import { Component, Input } from '@angular/core';
import { MenuItem } from 'src/@omnial/_models/navigation/menu.model';
import { MenuService } from 'src/@omnial/_services/navigation/menu.service';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-sidenav-mega',
  templateUrl: './../../../app/navigation/sidenav-mega/sidenav-mega.component.html',
  styleUrls: ['./../../../app/navigation/sidenav-mega/sidenav-mega.component.scss'],
  providers: [MenuService]
})
export class SidenavMegaComponent {
  @Input() menuItems: MenuItem[];
  @Input() menuParentId: string;
  @Input() level: number;

  constructor(private menuService: MenuService, public settings: AppSettings) { }

  onClick(menuId: string): void {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }
}
