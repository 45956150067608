<ng-container *ngIf="menuItems">
  <div *ngFor="let menu of parentMenu" class="menu-item">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button class="d-flex flex-row justify-content-start align-items-center sidenav-link sidenav-{{level}}" [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:false}" (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
      <span class="menu-title text-truncate">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.href && !menu.hasSubMenu" mat-button class="d-flex flex-row justify-content-start align-items-center sidenav-link sidenav-{{level}}" [attr.href]="menu.href || ''" [attr.target]="menu.target || ''" (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
      <span class="menu-title text-truncate">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.hasSubMenu" mat-button class="d-flex flex-row justify-content-start align-items-center sidenav-link sidenav-{{level}}" (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
      <span class="menu-title text-truncate">{{menu.title}}</span>
      <mat-icon class="menu-expand-icon transition-2 sidenav-link">arrow_drop_down</mat-icon>
    </a>
    <div *ngIf="menu.hasSubMenu" class="sub-menu" [id]="'sub-menu-'+menu.id">
      <app-sidenav-menu [menuItems]="menuItems" [menuParentId]="menu.id" [level]="level+1"></app-sidenav-menu>
    </div>
  </div>
</ng-container>
