import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RepositoryService } from '../repository.service';
import { RepositoryStaticService } from '../repository-static.service';
import { ActivatedRoute } from '@angular/router';
import { UrlRecord } from 'src/@omnial/_models/catalog/url-record.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { environment } from 'src/environments/environment';
import { UrlRequest } from 'src/@omnial/_models/catalog/url-request.model';

@Injectable()
export class UrlService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(
    public staticService: RepositoryStaticService,
    public repoService: RepositoryService,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public getUrlRecord(slug: string, parentSlug: string, location: string, bypassCache?: boolean): Observable<UrlRecord> {
    const urlRequest = new UrlRequest(slug, parentSlug, location)
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getBySeName('Url', slug).subscribe({
          next: (staticRes) => {
            if (staticRes) {
              observer.next(staticRes as UrlRecord);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.create('Url', urlRequest).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as UrlRecord);
                  observer.complete();
                },
                error: (e) => {
                  observer.error(e);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.create('Url', urlRequest).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as UrlRecord);
                observer.complete();
              },
              error: (e) => {
                observer.error(e);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.create('Url', urlRequest).subscribe({
          next: (resNoCache) => {
            observer.next(resNoCache as UrlRecord);
            observer.complete();
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          }
        }));
      }
    });
  }
}
