<div class="top-menu hide-print">
  <ng-container *ngIf="!isCheckout">
    <mat-toolbar color="accent">
      <mat-toolbar-row class=" justify-content-start align-items-center top-toolbar">
        <span class="flex-20 d-flex flex-row flex-wrap justify-content-start align-items-end">
          <div class="flex-25 flex-fill">
            <a [routerLink]="" (click)="scrollToTop()" *ngIf="showHam" routerLinkActive="router-link-active" name="Go to Top">
              <button mat-button color="primary" name="Scroll to Top" [attr.aria-label]="'Scroll to Top'">
                <mat-icon>arrow_upward</mat-icon>
              </button>
            </a>
          </div>
        </span>
        <span class="flex-60 middle-promo" style="text-align: center;">
          {{settings.companyDescription}}
        </span>
        <span class="d-flex flex-row flex-wrap flex-20 end-promo mt-2">
          <div class="flex-20">
            <a [routerLink]="'/search'" class="search-link" title="Search">
              <mat-icon class="search-button">search</mat-icon>
            </a>
          </div>
          <div *ngIf="settings?.socialLinks?.youtube" class="flex-20 social-icon">
            <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.youtube">
              <img src="/assets/images/social/youtube-small.png" alt="Youtube">
            </a>
          </div>
          <div *ngIf="settings?.socialLinks?.facebook" class="flex-20 social-icon">
            <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.facebook">
              <img src="/assets/images/social/facebook-small.png" alt="Youtube">
            </a>
          </div>
          <div *ngIf="settings?.socialLinks?.instagram" class="flex-20 social-icon">
            <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.instagram">
              <img src="/assets/images/social/instagram-small.png" alt="Youtube">
            </a>
          </div>
          <div *ngIf="settings?.socialLinks?.linkedIn" class="flex-20 social-icon">
            <a rel="noreferrer" target="_blank" [href]="settings?.socialLinks?.linkedIn">
              <img src="/assets/images/social/linkedin-small.png" alt="Youtube">
            </a>
          </div>
        </span>
      </mat-toolbar-row>
      <mat-toolbar-row class="d-flex flex-row justify-content-between align-items-center logo-toolbar">
        <div class="flex-10">
          <a routerLink="/" class="logo-link">
            <img src="assets/images/logo/logo-white.svg" alt="ELFA Home Page" class="logo" />
          </a>
        </div>
        <div class="flex-75">
          <app-menu></app-menu>
        </div>
        <div class="flex-15">

        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </ng-container>
</div>
