import { Component, OnInit, AfterViewInit, ViewChild, HostListener, OnDestroy, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import smoothscroll from 'smoothscroll-polyfill';

import { AppSettings } from 'src/app/app.settings';

// Models
import { SiteMapItem } from 'src/@omnial/_models/navigation/site-map.model';
import { ConfirmDialogComponent } from 'src/@omnial/shared/confirm-dialog/confirm-dialog.component';
import { PageState } from 'src/@omnial/_models/catalog/pageState.model';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { Menu } from 'src/@omnial/_models/navigation/menu.model';


//Services
import { BreadCrumbService } from 'src/@omnial/_services/navigation/breadcrumb.service';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { ProductCategoryService } from 'src/@omnial/_services/catalog/product-category.service';
import { MenuService } from 'src/@omnial/_services/navigation/menu.service';
import { SiteMapService } from 'src/@omnial/_services/navigation/sitemap.service';
import { VersionCheckService } from 'src/@omnial/_services/version-check.service';

@Component({
  selector: 'app-page',
  templateUrl: './../../app/pages/pages.component.html',
  styleUrls: ['./../../app/pages/pages.component.scss']
})

export class PagesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav', { static: false }) sidenav: any;
  public customer: Customer;
  public seName = '';
  public scrollStopTimer: NodeJS.Timer;
  public showBackToTop = false;
  public isCheckout = true;
  public homePage = true;
  public sitemapItems: SiteMapItem[] = null;
  public mega: Menu;
  public menuOverflow = 'hidden';
  public menuPosition = 'unset';
  public menuBackground = environment.topMenuBackground;
  public menuTop = '0';
  public menuLogo = true;
  public needsRefresh = false;
  public loggedIn = false;
  public dialogRef: MatDialogRef<ConfirmDialogComponent>;
  public shopItems: SiteMapItem[] = null;
  public accountPage = false;
  public subscriptions: Subscription[] = [];

  public facebookActive = environment.facebookActive;
  public facebookChat = environment.facebookChat;
  public facebookPageId = environment.facebookPageId;

  constructor(
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public customerService: CustomerService,
    public productService: ProductCategoryService,
    public settings: AppSettings,
    public router: Router,
    public titleService: Title,
    public sitemapService: SiteMapService,
    public menuService: MenuService,
    public versionCheckService: VersionCheckService,
    public zone: NgZone,
    public breadCrumbService: BreadCrumbService) {
    smoothscroll.polyfill();
    this.customerService.load();
    this.productService.load();
    localStorage.removeItem('refreshVersion');
    this.subscriptions.push(this.customerService.customer.subscribe(res => {
      this.customer = res as Customer;
      if (localStorage.getItem('token') && this.customer) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    }));
    const navEndEvents = router.events.pipe(filter(event => event instanceof NavigationEnd));
    this.subscriptions.push(navEndEvents.subscribe((event: NavigationEnd) => {
      // Replace any links from content that has been loaded
      this.links();
      this.images();
      if (localStorage.getItem('token') && this.customer) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
      const urlParts = event?.urlAfterRedirects?.split('/');
      if (urlParts) {
        this.seName = urlParts[urlParts.length - 1];
      }
      const title = this.getTitle(router.routerState, router.routerState.root);
      if (title && title[0]) {
        titleService.setTitle(`${title[0]} | ${this.settings?.storeSettings?.defaultPageTitle}`);
        breadCrumbService.setEndNode(title[0]);
      }
      const contentPage = this.contentPage(this.router.routerState, this.router.routerState.root);
      if (!contentPage || !contentPage[0]) {
        setTimeout(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }); // We set scrollPositionRestoration: 'disabled' in app.router but always scroll to the top to give the 'App Feel'. Product listing pages have scroll and filter restoration
      }
      if (event.url.startsWith('/checkout')) {
        this.isCheckout = true;
      } else {
        this.isCheckout = false;
      }
      if (event.url.startsWith('/account')) {
        this.accountPage = true;
      } else {
        this.accountPage = false;
      }
      if (!event.url || event.url === '/') {
        this.homePage = true;
      } else {
        this.homePage = false;
      }
      this.subscriptions.push(this.menuService.megaMenu.subscribe(
        res => {
          this.mega = res as Menu;
          if (this.mega?.items && this.mega?.items.length > 0) {
            setTimeout(() => { this.menuService.expandActiveSubMenu(); }); // Timing issue for rendering and selecting in service
          }
        }));
      this.subscriptions.push(this.sitemapService.siteMap.subscribe(
        res => {
          this.sitemapItems = res as SiteMapItem[];
          const shopItem = this.sitemapItems?.find(i => i.slug == 'shop');
          if (shopItem) {
            this.shopItems = [];
            const shopAll = new SiteMapItem();
            shopAll.routerLink = shopItem.link;
            shopAll.title = "All Products";
            this.shopItems.push(shopAll);
            this.shopItems = this.shopItems.concat(this.sitemapItems.filter(i => i.parentId == shopItem.id));
          }
          if (this.sitemapItems && this.sitemapItems.length > 0) {
            setTimeout(() => { this.sitemapService.expandActiveSubMenu(); }); // Timing issue for rendering and selecting in service
          }
        }));
      if (localStorage.getItem('refreshVersion')) {
        this.needsRefresh = true;
      } else {
        this.needsRefresh = false;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  ngOnInit(): void {
    // Replace any links from content that has been loaded
    window['angularOmnialClickReference'] = { component: this, zone: this.zone, routeToEvent: (event: any) => this.routeToEvent(event) };
    this.links();
    this.versionCheckService.initVersionCheck(environment.versionCheckUrl);
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          this.sidenav.close();
        }
      }));
  }

  public scrollToTop(): void {
    // As well as scrolling to the top we want to remove the pageOffset in the local data for a potential Category, Manufacturer or Search Result
    const pageStateString = localStorage.getItem(this.seName);
    if (pageStateString) {
      const pageState = JSON.parse(pageStateString) as PageState;
      pageState.pageNumber = 1;
      pageState.position = 0;
      pageState.scrollY = 0;
      localStorage.setItem(this.seName, JSON.stringify(pageState)); // Filters and any other info will be retained
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getTitle(state: any, parent: any): any {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  contentPage(state: any, parent: any): any {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.contentPage) {
      data.push(parent.snapshot.data.contentPage);
    }
    if (state && parent) {
      data.push(... this.contentPage(state, state.firstChild(parent)));
    }
    return data;
  }

  public refreshApp() {
    this.spinner.hide();
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, { disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to refresh the page?"
    this.subscriptions.push(this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        location.reload();
      }
      this.dialogRef = null;
    }));
  }

  public refreshVersion() {
    localStorage.removeItem('refreshVersion');
    this.needsRefresh = false;
    setTimeout(() => { location.reload(); }, 200);
  }

  public resetApp() {
    this.spinner.hide();
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, { disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = "Are you sure you want to reset and go to the home page?"
    this.subscriptions.push(this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        location.href = '/';
      }
      this.dialogRef = null;
    }));
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: { target: { documentElement: { scrollTop: number; }; }; }): void {
    if ($event.target.documentElement.scrollTop > 100) {
      this.menuOverflow = 'visible';
      this.menuPosition = 'fixed';
      this.menuBackground = environment.topMenuStaticBackground;
      this.menuLogo = false;
      this.showBackToTop = true;
    } else {
      this.menuOverflow = 'hidden';
      this.menuPosition = 'unset';
      this.menuBackground = environment.topMenuBackground;
      this.menuLogo = true;
      this.showBackToTop = false;
    }
  }

  public links() {
    setTimeout(() => {
      var contentBlocks = document.getElementsByClassName("nop-content");
      if (contentBlocks && contentBlocks.length > 0) {
        for (var x = 0; x < contentBlocks.length; x++) {
          var links = contentBlocks[x].getElementsByTagName("a");
          if (links) {
            for (var i = 0; i < links.length; i++) {
              if (links[i].getAttribute("ng-reflect-router-link") || links[i].className?.indexOf('ng-star-inserted') !== -1 || links[i].target) {
                // Already an Angular Link or a link with a target type
              } else {
                if (links[i].getAttribute("href")?.indexOf("/") === 0 && links[i].getAttribute("href")?.indexOf("#") === -1 && links[i].getAttribute("href")?.indexOf("?") === -1) { // The link must start with a forward slash to route it via angular with no fragment or querystring (encoding issues)
                  links[i].setAttribute("onclick", `event.preventDefault(); convertClick('${links[i].getAttribute("href")}')`);
                }
              }
            }
          }
        }
      }
    }, 1000); // One Second should be long enough to ensure we have the content loaded from where ever. If not we will just get a normal link and a page load (no real biggy)
  }

  public images() {
    if (environment.devImages) { // Designed for FED - Dev setups will not see uploaded images so post-processed here for convenience of examining CMS content
      setTimeout(() => {
        var content = document.getElementById("omnial-content");
        if (content) {
          const images = content.getElementsByTagName("img");
          if (images) {
            for (var i = 0; i < images.length; i++) {
              if (images[i].src?.indexOf(`${environment.baseUrl}/images/uploaded/`) === 0) {
                const absolute = images[i].src.replace(environment.baseUrl, environment.devImages);
                images[i].src = absolute;
              }
            }
          }
        }
      }, 2000); // Two Seconds to ensure we have the content loaded from where ever
    }
  }

  public routeToEvent(url: string) {
    this.zone.run(() => {
      if (url.indexOf('?q=') !== -1) { // Link to the Search Page with a QueryString ... tried to write this generically but failed
        return this.router.navigate([url.split('?q=')[0]], { queryParams: { q: url.split('?q=')[1] } });
      }
      if (url.indexOf('#') !== -1) {
        return this.router.navigate([url.split('#')[0]], { fragment: url.split('#')[1] });
      }
      return this.router.navigate([url]);
    });
  }
}

