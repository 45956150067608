<div *ngIf="!menu" class="menu-holder hide-print">
  <div class="d-flex flex-row flex-wrap">
    <div class="flex-25 d-flex flex-row">
      <img [src]="settings?.holdingImage?.medium" alt="Menu Waiting" />
    </div>
    <div class="flex-50 d-flex flex-row">
      <span>Loading Mega Menu</span>
    </div>
    <div class="flex-25 d-flex flex-row">
      <img [src]="settings?.holdingImage?.medium" alt="Menu Waiting" />
    </div>
  </div>
</div>
<mat-toolbar *ngIf="menu" class="top-navbar hide-print justify-content-center align-items-center" color="accent">
  <ng-container *ngFor="let topLevelItem of menu.items">
    <ng-container *ngIf="(!topLevelItem.isMega) && (!topLevelItem.children || topLevelItem.children.length === 0)">
      <a mat-button aria-label="Top Level Navigation" [name]="topLevelItem.name" [routerLink]="topLevelItem.link" routerLinkActive="horizontal-active-link" [class]="'top-level ' + topLevelItem.cssClass">
        {{topLevelItem.name}}
      </a>
    </ng-container>
    <ng-container *ngIf="topLevelItem.isMega && !topLevelItem.isLogo">
      <app-menu-mega [mega]="true">
        <button mat-button trigger aria-label="Top Level Navigation" [name]="topLevelItem.name" class="top-level {{topLevelItem.cssClass}}">
          {{topLevelItem.name}}
        </button>
        <div content class="menu-container text">
          <div class="d-flex flex-row mega-container">
            <div class="flex-100 d-flex flex-row">
              <div class="d-flex flex-row flex-wrap">
                <div *ngFor="let secondLevelItem of topLevelItem.children" [class]="'flex-25 d-flex flex-column second-level ' + secondLevelItem.cssClass">
                  <a mat-menu-item [routerLink]="secondLevelItem.link">
                    <span>
                      {{secondLevelItem.name}}
                    </span>
                  </a>
                  <ng-container *ngIf="secondLevelItem.children && secondLevelItem.children.length > 0">
                    <ng-container *ngFor="let thirdLevelItem of secondLevelItem.children">
                      <a mat-menu-item [routerLink]="thirdLevelItem.link" [class]="'third-level ' + thirdLevelItem.cssClass">{{thirdLevelItem.name}}</a>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-menu-mega>
    </ng-container>
    <ng-container *ngIf="topLevelItem.isMega && topLevelItem.isLogo">
      <app-menu-mega [mega]="true">
        <button mat-button trigger [class]="'top-level ' + topLevelItem.cssClass">{{topLevelItem.name}}</button>
        <div content class="menu-container logo">
          <div class="d-flex flex-row flex-wrap">
            <div class="flex-100 d-flex flex-column">
              <div class="d-flex flex-row flex-wrap">
                <div *ngFor="let secondLevelItem of topLevelItem.children" class="flex-16 d-flex flex-column second-level {{secondLevelItem.cssClass}}">
                  <a mat-menu-item [routerLink]="secondLevelItem.link">
                    <div class="menu-logo">
                      <picture>
                        <source [srcset]="secondLevelItem.imageUrl + '.webp'" type="image/webp" />
                        <img [src]="secondLevelItem.imageUrl" [alt]="secondLevelItem.name">
                      </picture>
                      <div class="menu-logo-caption">{{secondLevelItem.name}}</div>
                    </div>
                  </a>
                  <ng-container *ngIf="secondLevelItem.children && secondLevelItem.children.length > 0">
                    <ng-container *ngFor="let thirdLevelItem of secondLevelItem.children">
                      <a mat-menu-item [routerLink]="thirdLevelItem.link" class="'third-level">{{thirdLevelItem.name}}</a>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-menu-mega>
    </ng-container>
    <ng-container *ngIf="(!topLevelItem.isMega) && topLevelItem.children && topLevelItem.children.length > 0">
      <app-menu-mega [mega]="false">
        <button mat-button trigger [class]="'top-level ' + topLevelItem.cssClass">
          {{topLevelItem.name}}
        </button>
        <div content *ngFor="let secondLevelItem of topLevelItem.children" [class]="'second-level ' + secondLevelItem.cssClass">
          <a mat-menu-item [routerLink]="secondLevelItem.link"><b>{{secondLevelItem.name}}</b></a>
          <ng-container *ngIf="secondLevelItem.children && secondLevelItem.children.length > 0">
            <ng-container *ngFor="let thirdLevelItem of secondLevelItem.children">
              <a mat-menu-item [routerLink]="thirdLevelItem.link" [class]="'third-level ' + thirdLevelItem.cssClass">{{thirdLevelItem.name}}</a>
            </ng-container>
          </ng-container>
        </div>
      </app-menu-mega>
    </ng-container>
  </ng-container>
</mat-toolbar>
