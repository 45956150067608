export class ContactRequest {
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    phone: string;
    message: string;
    newsLetter: boolean;
    token: string;
}

export class ContactResult {
    success: boolean;
    errors: string[];
}
